import { Instance, types } from 'mobx-state-tree'

export type IRestaurantItem = Instance<typeof RestaurantItem>;

export const RestaurantItem = types
    .model("RestaurantItem", {
        restaurantId: types.identifierNumber,
        name: types.string,
        parentRestaurantId: types.maybeNull(types.number),
        parentRestaurantName: types.maybeNull(types.string)
    })