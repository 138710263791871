import { flow, Instance, types } from 'mobx-state-tree'
import { DayItem } from './Day';

export type IWeekItem = Instance<typeof WeekItem>;

export const WeekItem = types
    .model("WeekItem", {
        weekMenuId: types.identifierNumber,
        weekStartDate: types.string, // week start date
        weekNumber: types.number, // week number
        occursEveryNWeek: types.number,
        days: types.maybe(types.array(DayItem))
    }).actions(self => ({
        save: flow(function* () {
            self.days?.forEach(d => { d.setIsSaving() });

            for (let i = 0; i < (self.days ? self.days?.length : 0); i++) {
                if (self.days) {
                    yield self.days[i].save();
                }
            }

            self.days?.forEach(d => { d.setIsDirty(false); d.setIsSaving(false) });
        })
    })).views(self => ({
        isDirty() {
            let elems = self.days?.filter(d => d.isDirty);
            return (elems ? elems.length > 0 : false);
        },
        isSaving() {
            let elems = self.days?.filter(d => d.isSaving);
            return (elems ? elems.length > 0 : false);
        }
    }))