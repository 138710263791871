import { Instance, types } from 'mobx-state-tree'

export type ICityItem = Instance<typeof CityItem>;

export const CityItem = types
    .model("CityItem", {
        cityId: types.identifierNumber,
        name: types.string,
        parentCityId: types.maybeNull(types.number),
        parentCityName: types.maybeNull(types.string)
    })
    .actions(self => ({
        changeName(newName: string) {
            self.name = newName
        }
    }));
