import React, { Component } from 'react';
import { MsalContext } from "@azure/msal-react";
import { AuthError } from '@azure/msal-browser';

export default class LoginButton extends Component {
    static contextType = MsalContext;

    startLogin = async () => {
        try {
            await this.context.instance.loginPopup();
        } catch(err) {
            const error = err as AuthError;
            console.log(error.errorMessage);
        }
    }

    render() {
        return (
            <button className="btn btn-primary" onClick={this.startLogin}>Kirjaudu sisään</button>
        );
    }
}