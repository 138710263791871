import { Instance, types, flow, applySnapshot } from "mobx-state-tree";
import { CityItem } from './City'
import { RestaurantItem } from './Restaurant'
import { IWeekItem, WeekItem } from './Week'
import { SendApiRequest } from '../HttpClient/HttpClient'

const Store = types
  .model('Store', {
    cities: types.optional(types.array(CityItem), []),
    restaurants: types.optional(types.array(RestaurantItem), []),
    weeks: types.optional(types.array(WeekItem), []),
    selectedCity: types.maybe(types.reference(CityItem)),
    selectedRestaurant: types.maybe(types.reference(RestaurantItem)),
    selectedWeek: types.maybe(types.reference(WeekItem)),
    selectedRepeatMenu: types.optional(types.integer, 0)
  })
  .actions(self => ({
    loadCities: flow(function* () {
      let resp = yield SendApiRequest('cities');

      if (resp) {
        resp = resp.map((c: any) => {
          if (c.parentCity) {
            c.name = `${c.name} (${c.parentCity.name})`;
            c.parentCityId = c.parentCity.cityId;
            c.parentCityName = c.parentCity.name;
          }

          return c;
        })

        applySnapshot(self.cities, resp);
      }
    }),
    setSelectedCity: flow(function* (cityId: number) {
      self.selectedRestaurant = undefined;
      self.selectedWeek = undefined;
      self.selectedCity = self.cities.find(c => c.cityId === cityId);
      
      if(self.selectedCity?.parentCityId) {
        cityId = self.selectedCity.parentCityId;
      }

      let resp = yield SendApiRequest(`cities/${cityId}/restaurants`);

      if (resp) {
        resp = resp.map((r: any) => {
          if (r.parentRestaurant) {
            r.name = `${r.name} (${r.parentRestaurant.name})`;
            r.parentRestaurantId = r.parentRestaurant.restaurantId;
            r.parentRestaurantName = r.parentRestaurant.name;
          }

          return r;
        })

        applySnapshot(self.restaurants, resp);
      }
    }),
    setSelectedRestaurant: flow(function* (restaurantId: number) {
      self.selectedWeek = undefined;
      self.selectedRestaurant = self.restaurants.find(c => c.restaurantId === restaurantId);

      const resp = yield SendApiRequest(`restaurants/${restaurantId}/weeks`);
      applySnapshot(self.weeks, resp);
    }),
    setSelectedWeek: flow(function* (weekMenuId: number) {
      const sw = self.weeks.find(w => w.weekMenuId === weekMenuId)

      let rId = self.selectedRestaurant?.restaurantId;

      if(self.selectedRestaurant?.parentRestaurantId) {
        rId = self.selectedRestaurant?.parentRestaurantId;
      }

      // create and fetch week days from DB
      const weekMenuResponse: IWeekItem = yield SendApiRequest(`restaurants/${rId}/weekmenus/${weekMenuId}`);

      if (sw) {
        sw.days = weekMenuResponse.days;
      }

      self.selectedRepeatMenu = weekMenuResponse.occursEveryNWeek;
      self.selectedWeek = sw;
    }),
    setSelectedRepeatMenu(repeat: number) {
      self.selectedRepeatMenu = repeat;

      self.selectedWeek?.days?.forEach(d => { d.setIsDirty()});
    }
  }))

export type IStore = Instance<typeof Store>;
export default Store;