import { PrimaryButton, SpinButton, Stack, StackItem } from '@fluentui/react';
import { observer } from 'mobx-react';
import { Component } from 'react';
import Day from './Day';
import WeekMenuProps from './WeekMenuProps';
import styles from './WeekMenu.module.css';

const saveIcon = { iconName: 'SaveAll' };

class WeekMenu extends Component<WeekMenuProps, {}> {

    onSelectRepeatMenu = async (event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
        this.props.store.setSelectedRepeatMenu(newValue ? parseInt(newValue) : 0);
    }

    render() {
        const { store } = this.props;

        return (
            !store.selectedWeek?.isSaving() ?
                <div>
                    <h1>Viikko {store.selectedWeek?.weekNumber} - {store.selectedCity?.name} - {store.selectedRestaurant?.name}</h1>
                    <Stack horizontal tokens={{ childrenGap: 8 }} className={styles.toolbar}>
                        <StackItem>
                            Toista tämä ruokalista</StackItem>
                        <StackItem>
                            <SpinButton
                                onChange={this.onSelectRepeatMenu}
                                value={store.selectedRepeatMenu ? store.selectedRepeatMenu.toString() : '0'}
                                className={styles.sb}
                            />
                        </StackItem>
                        <StackItem>viikon välein</StackItem>
                        <StackItem>
                            <PrimaryButton
                                text='Tallenna kaikki' iconProps={saveIcon}
                                onClick={store.selectedWeek?.save}
                                disabled={!store.selectedWeek?.isDirty() || store.selectedWeek?.isSaving()}>
                            </PrimaryButton>
                        </StackItem>
                    </Stack>
                    {
                        store.selectedWeek?.days?.map(
                            (d, idx) => <Day key={idx} day={d} store={store} />
                        )
                    }
                </div>
                :
                <div>Saving, please wait...</div>
        );
    }
}

export default observer(WeekMenu)
