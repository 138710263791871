import React, { Component } from 'react';
import { MsalContext } from "@azure/msal-react";

export default class LogoutButton extends Component {
    static contextType = MsalContext;

    render() {
        return (
            <button className="btn btn-danger btn-sm" onClick={() => this.context.instance.logout()}>Kirjaudu ulos</button>
        );
    }
}