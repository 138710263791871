import { Stack, PrimaryButton, StackItem, Spinner, IStackTokens, DefaultButton, Icon } from '@fluentui/react';
import { Component } from 'react';
import DayMeal from './DayMeal'
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';
import styles from './Day.module.css';
import DayProps from './DayProps';
import { IMealItem } from '../models/Meal';
import { observer } from 'mobx-react';

const addIcon = { iconName: 'Add' };
const saveIcon = { iconName: 'Save' };

class Day extends Component<DayProps, {}> {

    render() {
        const { day, store } = this.props;

        let dateNow = new Date(day.date);

        const horizontalGapStackTokens: IStackTokens = {
            childrenGap: 10
        };

        return (
            <div className={[styles.dayBorderBox, 'ms-depth-8'].join(' ')}>
                <Stack tokens={{ childrenGap: 10 }} horizontal>
                    <StackItem>
                        <h2>{format(dateNow, 'EEEE', { locale: fi }).slice(0, -2)} {format(dateNow, 'd.M.', { locale: fi })}</h2>
                    </StackItem>
                    <StackItem className={styles.repeatDiv}>
                        {
                            store.selectedRepeatMenu > 0 ?
                                <span><Icon iconName='RecurringEvent' /> Toistuu joka {store.selectedRepeatMenu}. viikko</span> :
                                ''
                        }
                    </StackItem>
                </Stack>
                <Stack tokens={{ childrenGap: 10 }}>
                    <StackItem>
                        {day.meals?.map((m: IMealItem, idx) => <DayMeal key={idx} meal={m} />)}
                    </StackItem>
                    <StackItem>
                        <DefaultButton text="Lisää ateria" iconProps={addIcon} onClick={day.addMeal} />
                    </StackItem>
                    <StackItem>
                        <Stack horizontal tokens={horizontalGapStackTokens}>
                            <StackItem>
                                <PrimaryButton text='Tallenna' iconProps={saveIcon} disabled={day.meals?.length === 0 || !day.isDirty || day.isSaving}
                                    onClick={day.save} />
                            </StackItem>
                            <StackItem>
                                {
                                    day.isSaving ?
                                        <Spinner label="Tallennetaan muutoksia..." ariaLive="assertive" labelPosition="left" />
                                        :
                                        <></>
                                }
                            </StackItem>
                        </Stack>
                    </StackItem>
                </Stack>
            </div>
        );
    }
}

export default observer(Day)
