import { ComboBox, IconButton, StackItem, TextField } from '@fluentui/react';
import { observer } from 'mobx-react';
import { Component } from 'react';
import DayMealProps from './DayMealProps';
import styles from './DayMeal.module.css';

const removeIcon = { iconName: 'Delete' };

class DayMeal extends Component<DayMealProps, {}> {    

    onChangeDiet = async (ev?: any, checked?: boolean | undefined) => {
        console.log(ev?.target?.id);
    }

    onTypeChange = (event: any, option?: any, index?: any, value?: string) => {
        this.props.meal?.changeType(value !== undefined ? value : option.text);
    }

    onNameChange = (event: any, newValue: any) => {
        this.props.meal?.changeName(newValue)
    }

    render() {
        const { meal } = this.props;

        return (
            <div className='ms-Grid' dir='ltr'>
                <div className='ms-Grid-row'>
                    <div className={['ms-Grid-col ms-depth-8 ms-sm12 ms-lg6', styles.mealItem, styles.dayBorderBox].join(' ')}>
                        <StackItem>
                            <IconButton iconProps={removeIcon} title="Poista" ariaLabel="Remove" onClick={meal?.remove} className={styles.btnRemove}/>
                            <ComboBox
                                placeholder="Valitse ateria..."
                                label="Ateria"
                                options={[
                                    { key: 'Aamiainen', text: 'Aamiainen' },
                                    { key: 'Lounas', text: 'Lounas' },
                                    { key: 'Kasvislounas', text: 'Kasvislounas' },
                                    { key: 'Välipala', text: 'Välipala' },
                                    { key: 'Päivällinen', text: 'Päivällinen' },
                                    { key: 'Iltapala', text: 'Iltapala' }]}
                                onChange={this.onTypeChange}
                                allowFreeform={true}
                                autoComplete='on'
                                text={meal?.type}
                            />
                            <TextField
                                label='Ruoka'
                                onChange={this.onNameChange}
                                value={meal?.name}
                            />
                            {/* <StackItem>
                                <Stack>
                                    <Checkbox label="Laktoositon" id='l' onChange={this.onChangeDiet} />

                                    <Checkbox label="Gluteiiniton" id='g' onChange={this.onChangeDiet} />

                                    <Checkbox label="Maidoton" id='m' onChange={this.onChangeDiet} />

                                </Stack>
                            </StackItem> */}
                        </StackItem>
                    </div>
                    <div className='ms-Grid-col hiddenMdDown ms-lg6'>

                    </div>
                </div>
            </div >
        );
    }
}

export default observer(DayMeal)