import axios from "axios";
import {Auth} from '../models/Auth'

const apiUrl = process.env.REACT_APP_API_URL;

export async function SendApiRequest(apiCall: string, body: any = null): Promise<any> {    
    let auth = Auth.create();

    let accessToken = await auth.getAccessToken();

    if(!accessToken) return null;

    let config = {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    }

    if(body) {
        let response = await axios.patch(apiUrl + apiCall, body, config);
        return response.data;
    } else {
        let response = await axios.get(apiUrl + apiCall, config);
        return response.data;
    }
}