import { types, flow } from 'mobx-state-tree'
import { MsalConfig } from '../Authentication/MSALConfig'
import { AuthError } from '@azure/msal-browser';

var _msalInstance: any = null;

export const Auth = types
    .model("Auth", {
    })
    .actions(self => ({
        initialize: flow(function* load(msalInstance) {
            _msalInstance = msalInstance;
            yield _msalInstance.initialize();
        }),
        getAccessToken: flow(function* load() {
            const activeAccount = _msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
            const accounts = _msalInstance.getAllAccounts();

            if (!activeAccount && accounts.length === 0) {
                /*
                * User is not signed in. Throw error or wait for user to login.
                * Do not attempt to log a user in outside of the context of MsalProvider
                */
                console.log('no user');
                return null;
            }
            
            const request = {
                scopes: MsalConfig.scopes,
                account: activeAccount || accounts[0]
            };

            try {
                const authResult = yield _msalInstance.acquireTokenSilent(request);
                return authResult.accessToken;
            } catch (err) {
                const error = err as AuthError;
                // chrome private tab fallback => if acquireTokenSilent fails, we try acquireTokenRedirect method
                if (error.errorMessage.includes("AADB2C90077")) {
                    return _msalInstance.acquireTokenRedirect(request);
                } else {
                    console.log(err)
                }
            }
        })
    }
    ));

