import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalConfig } from './Authentication/MSALConfig'
import Store from './models/Store';
import { Provider } from 'mobx-react';
import { ThemeProvider } from '@fluentui/react';
import {Auth} from './models/Auth'

//debugging tools
import { onPatch } from 'mobx-state-tree';

// import makeInspectable from 'mobx-devtools-mst';

const appTheme = {
    palette: {
        themePrimary: '#10984b',
        themeLighterAlt: '#f2fbf6',
        themeLighter: '#cdefdb',
        themeLight: '#a4e0be',
        themeTertiary: '#5ac287',
        themeSecondary: '#24a55c',
        themeDarkAlt: '#0f8a44',
        themeDark: '#0d743a',
        themeDarker: '#09562a',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
};

const msalInstance = new PublicClientApplication(MsalConfig.config);
const auth = Auth.create()
await auth.initialize(msalInstance);

initializeIcons(/* optional base url */);

const store = Store.create()

msalInstance.addEventCallback((event) => {
    // load cities after successful login
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);

        store.loadCities();
    }
});

// Default to using the first account if no account is active on page load
if (msalInstance.getAllAccounts().length > 0) {
    if (!msalInstance.getActiveAccount()) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
    }

    store.loadCities();
}

onPatch(store, patch => {
    console.log(patch)
})

ReactDOM.render(
    <>
        <Provider store={store}>
            <MsalProvider instance={msalInstance}>
                <ThemeProvider theme={appTheme}>
                    <App />
                </ThemeProvider>
            </MsalProvider>
        </Provider>
    </>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
