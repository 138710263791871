import { LogLevel } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: 'f9f87f3a-36b0-4b81-a004-f59e57682e4a', // This is the ONLY mandatory field that you need to supply.
        authority: 'https://btorauth.ciamlogin.com/', // Replace the placeholder with your tenant subdomain 
        redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        pollIntervalMilliseconds: 0,
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }	
            }	
        }	
    }
};

const scopes = [ "api://f9f87f3a-36b0-4b81-a004-f59e57682e4a/kouluruoka.hallinta.write" ];

export const MsalConfig = { config: msalConfig, scopes: scopes };
