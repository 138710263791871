import { getParent, Instance, types } from 'mobx-state-tree'

export type IMealItem = Instance<typeof MealItem>;

export const MealItem = types
    .model("MealItem", {
        mealId: types.maybe(types.number),
        type: types.string,
        name: types.string
    })
    .actions(self => ({
        remove() {
            let p: any = getParent(self, 2);
            p.removeMeal(self);
        },
        changeType(value?: string) {
            self.type = value !== undefined ? value : '';
            
            let p: any = getParent(self, 2);
            p.setIsDirty();
        },
        changeName(value: string) {
            self.name = value;
            
            let p: any = getParent(self, 2);
            p.setIsDirty();
        }
    }))