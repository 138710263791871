import { Component } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import { observer, inject } from 'mobx-react'; //These functions make our components observable and be able to use the store

import Home from "./Home";
import LoginButton from "./components/LoginButton";
import LogoutButton from "./components/LogoutButton";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { MsalContext } from "@azure/msal-react";
import AppProps from './AppProps';
import logo from '../src/images/logo.svg'

class App extends Component<AppProps, {}> {
    static contextType = MsalContext;

    render() {
        const { store } = this.props;

        return (
            <Router>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row nav">
                        <div className="ms-Grid-col ms-sm6 vertMiddleLeft">
                            <img src={logo} alt=""/>
                            <span className="logoTitle">kouluruoka.fi hallinta</span>
                        </div>
                        <div className="ms-Grid-col ms-sm6 nav-right vertMiddleRight">
                            <AuthenticatedTemplate>
                                {this.context.accounts[0]?.name} <LogoutButton />
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                <LoginButton />
                            </UnauthenticatedTemplate>
                        </div>
                    </div>

                    <div className="ms-Grid-row content">
                        <div className="ms-Grid-col ms-sm12">
                            <Routes>
                                <Route path="/" element={<Home store={store} />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            </Router >
        );
    }
}

export default inject('store')(observer(App));