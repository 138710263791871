import { destroy, flow, getRoot, Instance, types } from 'mobx-state-tree'
import { SendApiRequest } from '../HttpClient/HttpClient';
import { IMealItem, MealItem } from './Meal';

export type IDayItem = Instance<typeof DayItem>;

export const DayItem = types
    .model("DayItem", {
        dayId: types.identifierNumber,
        date: types.string,
        meals: types.maybe(types.array(MealItem)),
        isDirty: types.optional(types.boolean, false),
        isSaving: types.optional(types.boolean, false)
    })
    .actions(self => ({
        addMeal() {
            self.meals?.push({ type: '', name: '' });
            self.isDirty = true;
        },
        removeMeal(item: IMealItem) {
            destroy(item);
            self.isDirty = true;
        },
        save: flow(function* () {
            self.isSaving = true;
            let r: any = getRoot(self);
            yield SendApiRequest(`days/${self.dayId}/${r.selectedRepeatMenu}`, self);
            self.isDirty = false;
            self.isSaving = false;
        }),
        setIsDirty(isDirty: boolean = true) {
            self.isDirty = isDirty
        },
        setIsSaving(isSaving: boolean = true) {
            self.isSaving = isSaving
        }
    }))