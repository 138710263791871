import { Component } from 'react';
import './App.css';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { observer } from 'mobx-react';
import HomeProps from './HomeProps';
import WeekMenu from './components/WeekMenu';
import { IWeekItem } from './models/Week'
import ss1 from './images/kouluruoka-hallinta-ui.png'

class Home extends Component<HomeProps, {}> {
    onSelectCity = async (event: any, item?: IDropdownOption) => {
        this.props.store.setSelectedCity(item ? parseInt(item?.key.toString()) : 0);
    }

    onSelectRestaurant = async (event: any, item?: IDropdownOption) => {
        this.props.store.setSelectedRestaurant(item ? parseInt(item?.key.toString()) : 0);
    }

    onSelectWeek = async (event: any, item?: IDropdownOption) => {
        this.props.store.setSelectedWeek(item ? parseInt(item?.key.toString()) : 0);
    }

    render() {
        const { store } = this.props;

        return (<>
            <AuthenticatedTemplate>
                <div className='ms-Grid' dir='ltr'>
                    <div className='ms-Grid-row'>
                        <div className='ms-Grid-col ms-sm5 ms-lg3'>
                            <Dropdown
                                placeholder='Valitse...'
                                label='Kaupunki/kunta'
                                options={store.cities.map(c => ({ key: c.cityId, text: c.name }))}
                                onChange={this.onSelectCity}
                                disabled={!store.cities || store.cities.length === 0}
                                selectedKey={store.selectedCity ? store.selectedCity.cityId : null}
                            />
                        </div>
                        <div className='ms-Grid-col ms-sm5 ms-lg3'>
                            <Dropdown
                                placeholder='Valitse...'
                                label='Koulu'
                                options={store.restaurants.map(r => ({ key: r.restaurantId, text: r.name }))}
                                onChange={this.onSelectRestaurant}
                                disabled={!store.restaurants || store.restaurants.length === 0}
                                selectedKey={store.selectedRestaurant ? store.selectedRestaurant.restaurantId : null}
                            />
                        </div>
                        <div className='ms-Grid-col ms-sm2 ms-lg2'>
                            <Dropdown
                                placeholder='Valitse...'
                                label='Viikko'
                                options={store.weeks.map((w: IWeekItem) => ({ key: w.weekMenuId, text: w.weekNumber.toString() }))}
                                disabled={!store.weeks || store.weeks.length === 0}
                                onChange={this.onSelectWeek}
                                selectedKey={store.selectedWeek ? store.selectedWeek.weekMenuId : null}
                            />
                        </div>
                    </div>
                    <div className='ms-Grid-row'>
                        <div className='ms-Grid-col ms-sm12'>
                            {store.selectedWeek && store.selectedRestaurant ?
                                <WeekMenu store={store} />
                                : <></>
                            }
                        </div>
                    </div>
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div className='ms-Grid' dir='ltr'>
                    <div className='ms-Grid-row'>
                        <div className='ms-Grid-col ms-sm1'>
                        </div>
                        <div className='ms-Grid-col ms-sm10'>
                            <h1>Tervetuloa kouluruoka.fi hallintasivustolle</h1>
                            <p>
                                Kouluruoka.fi:n hallintasivustolla voit syöttää ja muokata koulusi ruokalistoja.
                                Ruokalistat näytetään <a href="https://kouluruoka.fi">kouluruoka.fi</a>-sivustolla ja ovat katseltavissa ilmaiseksi selaimella tai mobiilisovelluksilla.
                            </p>
                            <h1>Ominaisuuksia</h1>
                            <ul>
                                <li>Ruokalistojen hallinta ja tulostus</li>
                                <li>Ruokalistojen näyttäminen infonäytöillä</li>
                                <li>Ruokalistojen näyttäminen upotettuna verkkosivulla</li>
                                <li>Kaupunkeja tai ravintoloita voi määritellä hierarkisesti, jolloin useamman sijainnin ruokalistat on helppo ja nopea päivittää yhdellä kertaa</li>
                                <li>Ruokalistoja voi asettaa toistumaan tietyllä viikkosyklillä</li>
                            </ul>                            
                            <p>
                                Hallintasivuston käyttö edellyttää rekisteröitymistä.
                                Sisäänkirjautuminen tapahtuu joko organisaation Microsoft 365 -tunnuksilla tai käyttäjätunnuksella ja salasanalla.
                            </p>
                            <p>
                                Kirjaudu sisään oikean yläreunan "Kirjaudu sisään" -painikkeesta.
                            </p>
                            <h1>Palvelun hinta</h1>
                            <ul>
                                <li>
                                    0€/ylläpitokäyttäjä/kk, sis. 1 ylläpitäjä. Tarvitsetko lisää ylläpitokäyttäjiä? <a href="mailto:myynti@kouluruoka.fi?subject=Tilaus">Ota yhteyttä</a>
                                </li>
                                <li>
                                    Tuki infonäytöille: <a href="mailto:myynti@kouluruoka.fi?subject=Infonäyttö">Ota yhteyttä</a>
                                </li>
                                <li>
                                    Mainokseton ruokalistasivu koululle: <a href="mailto:myynti@kouluruoka.fi?subject=Mainokseton+palvelu">Ota yhteyttä</a>
                                </li>
                            </ul>
                            <h1>Tulossa</h1>
                            <ul>
                                <li>Tarkemmat ainesosat ja ravintoarvot ruokalistoille</li>
                                <li>Lisätietojen syöttö ruokalistasivulle, esim. aukioloajat tai muu vapaavalintainen sisältö</li>
                            </ul>
                            <h1>Kuvankaappaus palvelusta</h1>
                            <p>
                                <img src={ss1} alt='Kuvankaappaus palvelusta'></img>
                            </p>
                        </div>
                        <div className='ms-Grid-col ms-sm1'>
                        </div>
                    </div>
                </div>
            </UnauthenticatedTemplate>
        </>
        );
    }
}

export default observer(Home)